import React, { useEffect, useState, useRef } from 'react';

function DivWithText({
  x,
  y,
  width,
  height,
  oldTopLeftX,
  oldTopLeftY,
  widthRatio,
  heightRatio,
  text,
  index,
  onChange,
  onResize,
  onClose,
  disabled = false,
}) {
  const style = {
    position: 'initial',
    left: x,
    top: y,
    width: width,
    height: height,
    // resize: disabled ? 'none' : 'both',
    resize: 'both',
    fontSize: disabled ? '11px' : '13px',
    backgroundColor: disabled ? 'yellow' : 'white',
    color: disabled ? 'red' : 'blue',
    border: '1px solid black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  };
  const containerDivStyle = {
    zIndex: 2,
    position: 'absolute',
    left: x,
    top: y,
    width: width,
    height: height,
    backgroundColor: 'transparent',
  };
  const [value, setValue] = useState(text);
  const [isHovering, setIsHovering] = useState(false);
  const [widthHeight, setWidthHeight] = useState({ width, height });

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef?.current;

    if (!textarea) return;

    const resizeObserver = new ResizeObserver((entries) => {
      if (width === entries[0].contentRect.width && height === entries[0].contentRect.height) {
        return;
      }
      handleResize(entries[0].contentRect);
    });

    resizeObserver.observe(textarea);
    return () => {
      // Cleanup the resizeObserver by unobserving all elements
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    setValue(text);
  }, [text]);

  const handleChange = (event) => {
    if (disabled) {
      return;
    }
    setValue(event.target.value);
    onChange(index, event.target.value);
  };
  const handleCloseClick = () => {
    onClose(index);
    setIsHovering(false);
  };
  const handleResize = ({ width, height }) => {
    // console.log('Canvas handleResize: ');
    if (disabled) {
      return;
    }
    const vertices = [
      {
        x: oldTopLeftX,
        y: oldTopLeftY,
      },
      {
        x: oldTopLeftX + width / widthRatio,
        y: oldTopLeftY,
      },
      {
        x: oldTopLeftX + width / widthRatio,
        y: oldTopLeftY + height / heightRatio,
      },
      {
        x: oldTopLeftX,
        y: oldTopLeftY + height / heightRatio,
      },
    ];
    setWidthHeight({ width, height });
    if (onResize) {
      onResize(index, vertices);
    }
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={containerDivStyle}>
      {isHovering && !disabled && (
        <button
          onClick={handleCloseClick}
          style={{
            position: 'absolute',
            top: '-12px',
            left: `${widthHeight.width - 6}px`,
            opacity: '0.9',
            backgroundColor: '#c90401',
            color: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '1.5rem',
            height: '1.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          X
        </button>
      )}
      <textarea
        ref={textareaRef}
        placeholder={disabled ? '' : 'Text를 입력해주세요.'}
        style={style}
        type="text"
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
}
export default DivWithText;
