import React, { useEffect, useState, useRef } from 'react';
import DivWithText from './DivWithText';

const canvasContainerDivStyle = {
  position: 'relative',
};

function Canvas(props) {
  const canvasRef = useRef(null);
  const rectCanvasRef = useRef(null);
  const canvasContainerDivRef = useRef(null);
  const CANVAS_WIDTH = 600;
  const [isPainting, setIsPainting] = useState(false);
  const [paintingRectInfo, setPaintingRectInfo] = useState({});

  useEffect(() => {
    const canvas = canvasRef.current;
    const rectCanvas = rectCanvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = CANVAS_WIDTH;
    const img = new Image();
    img.src = props.image.url;
    img.onload = () => {
      drawCanvas(canvas, rectCanvas, ctx, img);
    };
  }, [props.image, props.translatedJson]);

  const drawCanvas = (canvas, rectCanvas, ctx, img) => {
    const proportionalCanvasHeight = (img.height * canvas.width) / img.width;
    canvas.height = proportionalCanvasHeight;
    if (rectCanvas) {
      rectCanvas.height = proportionalCanvasHeight;
    }
    ctx.drawImage(img, 0, 0, canvas.width, proportionalCanvasHeight);
  };

  const getFileNameOnly = (s3Key) => {
    const path = decodeURIComponent(s3Key.replace(/\+/g, ' '));
    const parts = path.split('/');
    const file = parts.slice(-1)[0];
    return file;
  };

  const handleMousedown = (event) => {
    setIsPainting(true);
    setPaintingRectInfo({ x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY, width: 0, height: 0 });
  };

  const handleMousemove = (event) => {
    if (!isPainting) {
      return;
    }
    const ctx = event.target.getContext('2d');
    const { x, y } = paintingRectInfo;
    ctx.clearRect(0, 0, rectCanvasRef.current.width, rectCanvasRef.current.height);
    const newWidth = event.nativeEvent.offsetX - paintingRectInfo.x;
    const newHeight = event.nativeEvent.offsetY - paintingRectInfo.y;
    paintingRectInfo.width = newWidth;
    paintingRectInfo.height = newHeight;
    setPaintingRectInfo(paintingRectInfo);
    ctx.fillStyle = 'rgba(0, 0, 255, 0.3)';
    ctx.fillRect(x, y, newWidth, newHeight);
  };

  const handleMouseup = (event) => {
    if (!setIsPainting) {
      return;
    }
    const canvas = event.target;
    const ctx = canvas.getContext('2d');
    const { width, height } = props.imageWidthHeightMap[props.image.file];
    console.log('paintingRectInfo: ', paintingRectInfo);

    setIsPainting(false);
    setPaintingRectInfo(null);
    if (paintingRectInfo.width > 10 && paintingRectInfo.height > 10) {
      const rectObject = {
        vertices: calculateOriginalVertices({
          ...paintingRectInfo,
          originalWidth: width,
          originalHeight: height,
          canvasHeight: canvas.height,
          canvasWidth: canvas.width,
        }),
        source_text: '',
        image: props.image.file,
        width,
        height,
      };

      props.onRectangleCreate(rectObject);
    }
    ctx.clearRect(0, 0, rectCanvasRef.current.width, rectCanvasRef.current.height);
  };

  const calculateOriginalVertices = (imageInfo) => {
    const { x, y, width, height, originalWidth, originalHeight, canvasWidth, canvasHeight } = imageInfo;
    const widthRatio = originalWidth / canvasWidth;
    const heightRatio = originalHeight / canvasHeight;

    const vertices = [
      {
        x: x * widthRatio,
        y: y * heightRatio,
      },
      {
        x: (x + width) * widthRatio,
        y: y * heightRatio,
      },
      {
        x: (x + width) * widthRatio,
        y: (y + height) * heightRatio,
      },
      {
        x: x * widthRatio,
        y: (y + height) * heightRatio,
      },
    ];
    return vertices;
  };

  const renderBubbleText = (image, dataJson, onChange, onResize, onClose, disabled = false) => {
    if (!dataJson) {
      return;
    }
    const imageInformation = props.imageWidthHeightMap[props.image.file];
    if (!imageInformation) {
      return;
    }
    const CANVAS_HEIGHT = (imageInformation.height * CANVAS_WIDTH) / imageInformation.width;
    return dataJson.map((bubbleTextData, index) => {
      if (getFileNameOnly(bubbleTextData.image) !== getFileNameOnly(image.file)) {
        return null;
      }
      if (bubbleTextData.isDeleted) {
        return null;
      }
      const { vertices, width, height } = bubbleTextData;

      const xArray = vertices.map((v) => v.x);
      const yArray = vertices.map((v) => v.y);

      const oldTopLeftX = Math.min(...xArray);
      const oldTopLeftY = Math.min(...yArray);

      const widthRatio = CANVAS_WIDTH / width;
      const heightRatio = CANVAS_HEIGHT / height;

      const newTopLeftX = oldTopLeftX * widthRatio;
      const newTopLeftY = oldTopLeftY * heightRatio;

      // Calculate the width and height of the rectangle
      const rectWidth = (Math.max(...xArray) - oldTopLeftX) * widthRatio;
      const rectHeight = (Math.max(...yArray) - oldTopLeftY) * heightRatio;

      const text = disabled ? bubbleTextData.translated : bubbleTextData.source_text;

      return (
        <DivWithText
          key={`${index}`}
          x={newTopLeftX}
          y={newTopLeftY}
          width={rectWidth}
          height={rectHeight}
          oldTopLeftX={oldTopLeftX}
          oldTopLeftY={oldTopLeftY}
          widthRatio={widthRatio}
          heightRatio={heightRatio}
          text={text}
          index={index}
          onChange={onChange}
          onResize={onResize}
          onClose={onClose}
          disabled={disabled}
        />
      );
    });
  };

  return (
    <div style={canvasContainerDivStyle} ref={canvasContainerDivRef}>
      <canvas
        ref={canvasRef}
        width={600}
        style={{ position: 'initial', left: 0, top: 0, zIndex: 0, backgroundColor: 'transparent' }}
      ></canvas>
      {props.parsedJson && (
        <canvas
          ref={rectCanvasRef}
          width={600}
          onMouseDown={handleMousedown}
          onMouseMove={handleMousemove}
          onMouseUp={handleMouseup}
          style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, backgroundColor: 'transparent' }}
        ></canvas>
      )}
      {props.parsedJson &&
        renderBubbleText(props.image, props.parsedJson, props.onChange, props.onResize, props.onClose, false)}
      {props.translatedJson && renderBubbleText(props.image, props.translatedJson, null, null, null, true)}
    </div>
  );
}

export default Canvas;
