import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StepsSeparated from './components/StepsSeparated';

function App() {
  return (
    <div className="App">
      <h1>OCR Refinement Editor</h1>
      <Router>
        <Routes>
          <Route path="/" element={<StepsSeparated />} />
        </Routes>
        <p>Release Version: {process.env.REACT_APP_RELEASE_TIME || 'DEV'}</p>
      </Router>
    </div>
  );
}

export default App;
