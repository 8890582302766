import '../App.css';
import Canvas from './Canvas';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const API_KEY = 'TWIGFARM_DEV_API_KEY_WEBSOCKET'; // dev & prod the same
const webSocketUrl = 'ws://54.180.159.201:5001'; // dev & prod the same

const MODE = 'PROD';

const API_BASEURL =
  MODE === 'DEV'
    ? 'https://seqtlevcy8.execute-api.ap-northeast-2.amazonaws.com/dev'
    : 'https://x7ztt0l1yg.execute-api.ap-northeast-2.amazonaws.com/v1';

const BUCKET_NAME = MODE === 'DEV' ? 'kidari.api.private' : 'ai-tms';
const FOLDER_NAME = 'ai_jp/demo-inputs/hyuntaek-test';
const KIDARI_PROD_API_KEY = 'KIDARI_BE_DEVTEAM_PROD';

function StepsSeparated() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [images, setImages] = useState([]);
  const [parsedJson, setParsedJson] = useState([]);
  const [translatedJson, setTranslatedJson] = useState([]);
  const [translationId, setTranslationId] = useState(null);
  const [s3BucketText, setS3BucketText] = useState(queryParams.get('s3_bucket') || BUCKET_NAME);
  const [s3KeyText, setS3KeyText] = useState(queryParams.get('s3_key') || FOLDER_NAME);
  const [apiKeyInput, setApiKeyInput] = useState(KIDARI_PROD_API_KEY);
  const [loading, setLoading] = useState(false);
  const [imageWidthHeightMap, setImageWidthHeightMap] = useState({});

  const wsRef = useRef(null);

  useEffect(() => {
    const ws = new WebSocket(webSocketUrl);
    wsRef.current = ws;

    ws.onopen = () => {
      handleWebsocketOpen();
    };
    ws.onmessage = async (event) => {
      handleWebsocketMessage(event);
    };
    ws.onclose = () => {
      handleWebsocketClose();
    };

    return () => {
      // Close WebSocket connection on unmount
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const handleWebsocketOpen = () => {
    console.log('Websocket connected');
    setLoading(false);
  };
  const handleWebsocketMessage = async (event) => {
    console.log('event.data: ', event.data);
    const response = JSON.parse(event.data);
    if (response.type === 'heartbeat') {
      return;
    }

    console.log('response.state: ', response.state);
    if (response.state === 'parsed') {
      sessionStorage.setItem(response.translation_id, JSON.stringify(response.data));
      loadImageParsedJson(response.data);
    } else if (response.state === 'translated') {
      setTranslatedJson(response.data);
    }
    await requestImageUrls(response.translation_id);
    setLoading(false);
  };

  const loadImageParsedJson = (parsedData) => {
    const localWidthHeightMap = {};
    for (const bubble of parsedData) {
      localWidthHeightMap[bubble.image] = {
        width: bubble.width,
        height: bubble.height,
      };
    }
    setImageWidthHeightMap(localWidthHeightMap);
    setParsedJson(parsedData);
  };
  const connect = () => {
    let ws = wsRef.current;

    if (ws.readyState !== WebSocket.OPEN) {
      console.log('Attempting to reconnect...');
      ws.close();
      wsRef.current = new WebSocket(webSocketUrl);
      ws = wsRef.current;
    }
    ws.onopen = () => {
      handleWebsocketOpen();
    };
    ws.onmessage = async (event) => {
      handleWebsocketMessage(event);
    };
    ws.onclose = () => {
      handleWebsocketClose();
    };
  };

  const handleWebsocketClose = () => {
    console.log('WebSocket disconnected');
    // Attempt to reconnect after a delay
    setTimeout(() => {
      console.log('Reconnecting to WebSocket server...');
      connect();
    }, 5000);
  };

  const handleS3BucketChange = (event) => {
    setS3BucketText(event.target.value);
  };

  const handleS3KeyChange = (event) => {
    setS3KeyText(event.target.value);
  };

  const requestImageUrls = async (translationId) => {
    const recropS3Key = `ai_jp/recropped/${translationId}/`;
    const response = await axios.get(`${API_BASEURL}/image-urls?s3_bucket=${s3BucketText}&s3_key=${recropS3Key}`, {
      headers: {
        'x-api-key': API_KEY,
      },
    });
    console.log('response: ', response);
    setImages(response.data.image_urls);
  };
  const requestStep1OCR = async (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }
    const result = window.confirm('OCR을 요청하겠습니다. 진행하시겠습니까?');
    if (!result) {
      return;
    }
    setTranslationId(null);
    setLoading(true);
    const url = `${API_BASEURL}/step1-ocr`;

    const headerData = {
      headers: {
        'content-type': 'application/json',
        'x-api-key': API_KEY,
      },
    };
    const payload = {
      s3_bucket: s3BucketText,
      s3_key: s3KeyText,
    };
    try {
      const result = await axios.post(url, payload, headerData);
      console.log('result: ', result);
      const localTranslationId = result.data.translation_id;
      setTranslationId(localTranslationId);

      alert(
        `성공적으로 OCR을 요청했습니다.\n작업이 완료되면 화면에 이미지들이 표시됩니다.\ntranslation_id: ${localTranslationId}`
      );
      const ws = wsRef.current;
      ws.send(
        JSON.stringify({
          method: 'registerTranslationId',
          translationId: localTranslationId,
        })
      );
    } catch (error) {
      setLoading(true);
      alert('요청이 실패했습니다. S3 정보를 확인해주세요.');
    }
  };
  const requestStep2Translations = async (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }
    const result = window.confirm(`번역을 요청하겠습니다. 진행하시겠습니까?`);
    if (!result) {
      return;
    }

    setLoading(!apiKeyInput);
    setTranslatedJson([]);
    const url = `${API_BASEURL}/step2-translations`;

    const headerData = {
      headers: {
        'content-type': 'application/json',
        'x-api-key': apiKeyInput || API_KEY,
      },
    };
    const cleansedParsedJson = [];
    for (const item of parsedJson) {
      if (item.isDeleted) {
        continue;
      }
      cleansedParsedJson.push(item);
    }
    const payload = {
      data: cleansedParsedJson,
      translation_id: translationId,
    };
    try {
      const result = await axios.post(url, payload, headerData);
      console.log('result: ', result);

      alert(`성공적으로 번역을 요청했습니다.\n\ntranslation_id: ${translationId}`);
      const ws = wsRef.current;
      ws.send(
        JSON.stringify({
          method: 'registerTranslationId',
          translationId,
        })
      );
    } catch (error) {
      setLoading(false);
      alert('번역 요청이 실패했습니다. 서버 로그를 확인해주세요.');
    }
  };

  const handleApiKeyInputChange = (event) => {
    setApiKeyInput(event.target.value);
  };

  const handleRefine = (index, value) => {
    const updatedParsedJson = [...parsedJson];
    const prevParsedJson = { ...parsedJson[index] };
    prevParsedJson.source_text = value;
    updatedParsedJson[index] = prevParsedJson;
    setParsedJson(updatedParsedJson);
  };

  const handleResize = (index, value) => {
    setParsedJson((prevParsedJson) => {
      prevParsedJson[index].vertices = value;
      return prevParsedJson;
    });
  };

  const handleClose = (index) => {
    console.log('index: ', index);
    const updatedParsedJson = [...parsedJson];
    const prevParsedJson = { ...parsedJson[index] };
    prevParsedJson.isDeleted = true;
    updatedParsedJson[index] = prevParsedJson;
    setParsedJson(updatedParsedJson);
  };

  const handleRectangleCreate = (rectObject) => {
    const updatedParsedJson = [...parsedJson, { ...rectObject }];
    setParsedJson(updatedParsedJson);
  };

  const handleRestore = async (event) => {
    event.preventDefault();
    const result = window.confirm(
      'OCR 결과물로 되돌립니다. 현재까지 작업하신 정제 내용은 사라집니다. 진행하시겠습니까?'
    );
    if (!result) {
      return;
    }
    setLoading(true);

    const jsonData = sessionStorage.getItem(translationId);
    loadImageParsedJson(JSON.parse(jsonData));
    await requestImageUrls(translationId);
    setLoading(false);
  };

  return (
    <div className="main-container">
      <div className="form-container">
        {!translationId ? (
          <form onSubmit={requestStep1OCR}>
            <label htmlFor="s3-bucket-text">S3 Bucket:</label>
            <input
              type="text"
              id="s3-bucket-text"
              name="s3-bucket-text"
              value={s3BucketText}
              onChange={handleS3BucketChange}
            />

            <label htmlFor="s3-key-text">S3 Key:</label>

            <input type="text" id="s3-key-text" name="s3-key-text" value={s3KeyText} onChange={handleS3KeyChange} />
            <button type="submit" disabled={loading || translationId}>
              {loading ? 'Step 1: Loading...' : 'Step 1: OCR 요청하기'}
            </button>
          </form>
        ) : (
          <div>
            <form onSubmit={requestStep2Translations}>
              <p className="api-key-explanation">API key를 입력하지 않으면 화면에 번역결과물이 표시됩니다.</p>
              <p className="api-key-explanation">
                API key를 입력하면 해당 API key와 연결되어있는 Callback URL로 결과물이 전달됩니다.
              </p>
              <label htmlFor="api-key">API Key:</label>
              <input
                type="text"
                id="api-key"
                name="api-key"
                disabled={loading || !translationId}
                value={apiKeyInput}
                onChange={handleApiKeyInputChange}
              />

              <button type="submit" disabled={loading || !translationId}>
                {loading ? 'Loading...' : 'Step 2: 번역요청하기'}
              </button>
              <button className="reset-button" onClick={handleRestore} disabled={loading}>
                Reset
              </button>
            </form>
          </div>
        )}
      </div>

      <div className="canvas-container">
        <div className="canvas-images">
          {images.map((image) => {
            return <Canvas key={`${image.file}_image-only`} image={image} imageWidthHeightMap={imageWidthHeightMap} />;
          })}
        </div>
        <div className="canvas-images">
          {images.map((image) => {
            return (
              <Canvas
                key={`${image.file}_parsed`}
                image={image}
                parsedJson={parsedJson}
                onChange={handleRefine}
                onResize={handleResize}
                onClose={handleClose}
                onRectangleCreate={handleRectangleCreate}
                imageWidthHeightMap={imageWidthHeightMap}
              />
            );
          })}
        </div>
        <div className="canvas-images">
          {translatedJson?.length > 0 &&
            images.map((image) => {
              return (
                <Canvas
                  key={`${image.file}_translated`}
                  image={image}
                  translatedJson={translatedJson}
                  imageWidthHeightMap={imageWidthHeightMap}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default StepsSeparated;
